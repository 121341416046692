var main = require('integrations/main');

main.baseFiles.menu = require('./components/menu');
main.baseFiles.footer = require('./components/footer');
main.baseFiles.miniCart = require('./components/miniCart');
main.baseFiles.base = require('./product/base');
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.productTile = require('./components/productTile');
main.baseFiles.search = require('./components/search').init;
main.baseFiles.cart = require('./cart');
main.baseFiles.storeLocator = require('./storeLocator/storeLocator');

module.exports = main;
