'use strict';

// Grab the base in rvw_integrations_core
var baseIntegrations = require('integrations/product/base');
var baseIntegrationsUpdateAvailabilityProcess = baseIntegrations.methods.updateAvailabilityProcess;
var baseIntegrationsAddToCart = baseIntegrations.addToCart;

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer, currentTarget) {
    var methods = this;

    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
                url: selectedValueUrl,
                method: 'GET',
            })
            .then((data) => {
                methods.handleVariantResponse(data, $productContainer, currentTarget);
                methods.updateOptions(data.product.optionsHtml, $productContainer);
                methods.updateQuantities(data.product.quantities, $productContainer);
                methods.updateAttributeContent(data, $productContainer);
                methods.updateKlarnaMessaging(data, $productContainer);
                methods.updateBadges(data, $productContainer);
                methods.updateAdditionalProductCheckbox(data, $productContainer);

                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
            })
            .always(() => $.spinner().stop());
    }
}

/**
 * Fetch html updates for variation -- SFCC struggles to parse waincludes
 *
 * @param {Object} data the data from attributeSelect ajax call
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateKlarnaMessaging (data, $productContainer) {
    if (data && data.product && [undefined, null, ''].indexOf(data.product.klarnaMessageHtml) === -1) {
        var $klarnaTemplate = $(data.product.klarnaMessageHtml);
        var $klarnaMessage = $klarnaTemplate.filter('.klarna-msg');
        var $klarnaModalContent = $klarnaTemplate.filter('.klarna-modal-content');

        if ($klarnaMessage.length === 0) {
            $klarnaMessage = $klarnaTemplate.find('.klarna-msg');
        }

        if ($klarnaModalContent.length === 0) {
            $klarnaModalContent = $klarnaTemplate.find('.klarna-modal-content');
        }

        if ($klarnaMessage.length !== 0) {
            $productContainer.find('.klarna-msg').replaceWith($klarnaMessage);
        }

        if ($klarnaModalContent.length !== 0) {
            $productContainer.find('.klarna-modal-content').replaceWith($klarnaModalContent);
        }
    }
}

/**
 * Fetch html updates for variation -- SFCC struggles to parse waincludes
 *
 * @param {Object} data the data from attributeSelect ajax call
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateBadges (data, $productContainer) {
    if (data && data.product && [undefined, null].indexOf(data.product.badgesHtml) === -1) {
        var $badgesTemplate = $(data.product.badgesHtml);
        var $badges = $badgesTemplate.filter('.badge-product-container');

        if ($badges.length === 0) {
            $badges = $badgesTemplate.find('.badge-product-container');
        }

        $productContainer.find('.badge-product-container').empty().html($badges.html());
    }
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailabilityProcess(response, $productContainer) {
    var $availabilityTitle = $('.icon-checkmark, .availability', '.product-availability .availability-container');

    if (response.product.readyToOrder && response.product.available) {
        $availabilityTitle.replaceWith('<span class="icon-checkmark mb-0 mr-2" aria-label="' + response.resources.availabilityLabel + '"></span>');
    } else {
        $availabilityTitle.replaceWith('<label class="availability non-input-label mb-0 mr-2">' + response.resources.availabilityLabel + ':</label>');
    }

    baseIntegrationsUpdateAvailabilityProcess.apply(this, arguments);
}

/**
 * Updates the additional product content in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAdditionalProductCheckbox(response, $productContainer) {
    var $additionalProductCheckboxContainer = $productContainer.find('.additional-product-container').empty();

    if (response.product.additionalProductHtml) {
        $additionalProductCheckboxContainer.html(response.product.additionalProductHtml);
    }
}

/**
 * Extend addToCart DOM initializer (no inputs or output)
 */
function addToCart() {
    // Modify form
    $(document).on('updateAddToCartFormData', function (e, form) {
        var $addToCartBtn = $(e.target).filter('.add-to-cart').eq(0);
        var $container = $addToCartBtn.closest('.qty-cart-container');
        var $checkbox = $container.find('.additional-product-container input[name="additional-product"]');
        var pidsObj = [];

        // Update form data
        if ($checkbox.prop('checked')) {
            if (form.pidsObj) {
                pidsObj = JSON.parse(form.pidsObj);
            }

            // Package original product
            if (pidsObj.length === 0) {
                pidsObj.push({
                    pid: form.pid,
                    childProducts: form.childProducts,
                    qty: form.quantity, // Note qty is for pidsObj and quantity is for form
                    options: form.options
                });
            }

            // Prep Additional Product
            pidsObj.push({
                pid: $checkbox.val(),
                qty: 1
            });

            form.pidsObj = JSON.stringify(pidsObj);
        }
    });

    baseIntegrationsAddToCart.apply(this, arguments);
}

baseIntegrations.methods.attributeSelect = attributeSelect;
baseIntegrations.methods.updateKlarnaMessaging = updateKlarnaMessaging;
baseIntegrations.methods.updateBadges = updateBadges;
baseIntegrations.methods.updateAvailabilityProcess = updateAvailabilityProcess;
baseIntegrations.methods.updateAdditionalProductCheckbox = updateAdditionalProductCheckbox;

baseIntegrations.addToCart = addToCart;

module.exports = baseIntegrations;
