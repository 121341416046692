'use strict';

var integrationsCart = require('integrations/cart');

var cart = require('./cart/cart');

integrationsCart.baseFiles.cart = function () {
    return cart.init.call(cart);
};

module.exports = integrationsCart;