'use strict';

/**
 * Updates Why We Love It section after selecting variant attribute
 */
function updateWhyWeLoveIt() {
    $('body').on('product:afterAttributeSelect', function (e, data) {
        if (data && data.data && data.data.product && data.container) {
            var $productContainer = data.container;
            var product = data.data.product;

            if (product.whyWeLoveIt) {
                $productContainer.find('.product-whyWeLoveIt')
                    .show()
                    .html(product.whyWeLoveIt);
            } else {
                $productContainer.find('.product-whyWeLoveIt')
                    .hide();
            }
        }
    });
}

module.exports = {
    updateWhyWeLoveIt: updateWhyWeLoveIt
}