/* globals google */
'use strict';

var coreStoreLocator = require('core/storeLocator/storeLocator');

coreStoreLocator.storeSelectedInHeader = function () {
    $('body').on('store:selected', function (e, data) {
        if (data.scope.parents('#home-store-picker-container').length) {
            var spinner = data.scope.spinner();
            spinner.start();
            $.ajax({
                url: module.exports.methods.appendToUrl(data.scope.data('setpreferredstoreactionurl'), { preferredStoreID: data.storeID }),
                type: 'get',
                dataType: 'json',
                success: function (result) {
                    if (result && result.success) {
                        var $prompt = $('.js-storelocator-header-prompt');
                        var promptText = $prompt.data('prompt-text');
                        data.scope.find('.store-locator-failed-to-set-preferred').hide();
                        data.scope.closest('.modal').modal('hide');
                        $prompt.html(promptText + ': ' + data.storeInfoJSON.name);

                        var address = $.map([data.storeInfoJSON.address1, data.storeInfoJSON.address2, data.storeInfoJSON.city, data.storeInfoJSON.stateCode, data.storeInfoJSON.postalCode], (v) => v);
                        $prompt.attr('data-address', address.join(', ')).data('address', address.join(', '));

                        var title = $.map([data.storeInfoJSON.name, data.storeInfoJSON.address1, data.storeInfoJSON.address2, data.storeInfoJSON.city, data.storeInfoJSON.stateCode, data.storeInfoJSON.postalCode], (v) => v);
                        $prompt.attr('title', title.join(', '));
                    } else {
                        data.scope.find('.store-locator-failed-to-set-preferred').show();
                    }
                },
                complete: function () {
                    spinner.stop();
                }
            }).fail(function(){
                data.scope.find('.store-locator-failed-to-set-preferred').show();
            });
        }
    })
};

coreStoreLocator.selectStoreInSearch = function() {
    $('body').on('click', '.store-select-map', function (e) {
        e.preventDefault();
        var scope = $(this);
        var storeInfoJSON = scope.data('store-info');
        scope.spinner().start();
        $.ajax({
            url: scope.data('action'),
            type: 'get',
            dataType: 'json',
            success: function (result) {
                if (result && result.success) {
                    var $prompt = $('.js-storelocator-header-prompt');
                    var promptText = $prompt.data('prompt-text');
                    scope.closest('.store-locator-failed-to-set-preferred').hide();
                    $prompt.html(promptText + ': ' + storeInfoJSON.name);

                    var address = $.map([storeInfoJSON.address1, storeInfoJSON.address2, storeInfoJSON.city, storeInfoJSON.stateCode, storeInfoJSON.postalCode], (v) => v);
                    $prompt.attr('data-address', address.join(', ')).data('address', address.join(', '));

                    var title = $.map([storeInfoJSON.name, storeInfoJSON.address1, storeInfoJSON.address2, storeInfoJSON.city, storeInfoJSON.stateCode, storeInfoJSON.postalCode], (v) => v);
                    $prompt.attr('title', title);
                } else {
                    scope.closest('.store-locator-failed-to-set-preferred').show();
                }
            },
            complete: function () {
                scope.spinner().stop();
            }
        }).fail(function(){
            scope.closest('.store-locator-failed-to-set-preferred').show();
        });
    });
};

coreStoreLocator.changeRadius = function () {
    $('body').on('change', '.store-locator-container .radius', function () {
        var radius = $(this).val();
        var scope = $(this).closest('.store-locator-container');
        var searchPostal = scope.find("input[name=\"postalCode\"]")?.val();
        var searchKeys = scope.find('.results').data('search-key');
        var url = $(this).data('action-url');
        var urlParams = {radius: radius};

        searchKeys = typeof searchKeys !== 'object' ? {} : searchKeys;

        if (searchPostal) {
            searchKeys.postalCode = searchPostal;
        }

        if (searchKeys.postalCode) {
            Object.assign(urlParams, { postalCode: searchKeys.postalCode });
        } else if (searchKeys.lat && searchKeys.long) {
            Object.assign(urlParams, { lat: searchKeys.lat, long: searchKeys.long });
        }

        scope.find('.filter-results [data-filter]').removeClass('active btn-primary').addClass('btn-secondary').hide();
        scope.find('.filter-results [data-filter="all"]').addClass('active btn-primary').removeClass('btn-secondary').show();

        url = module.exports.methods.appendToUrl(url, urlParams);
        var dialog = $(this).closest('.in-store-inventory-dialog');
        var spinner = dialog.length ? dialog.spinner() : $.spinner();
        spinner.start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                module.exports.methods.updateStoresResults(data, scope);
                scope.find('.select-store').prop('disabled', true);
            },
            complete: function () {
                spinner.stop();
            }
        });
    });
},

module.exports = coreStoreLocator;
