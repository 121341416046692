'use strict';

var coreCart = require('core/cart/cart');

var coreCartInit = coreCart.init;

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
coreCart.updateApproachingDiscounts = function (approachingDiscounts) {
	var html = '';
	$('.approaching-discounts').empty();
	if (approachingDiscounts.length > 0) {
		approachingDiscounts.forEach(function (item) {
			html += '<div class="single-approaching-discount ' + (item.type || 'unknown') + '-type-discount ">'
				+ item.discountMsg + '</div>';
		});
	}
	$('.approaching-discounts').append(html);
};

/**
 * Fetch html updates for variation -- SFCC struggles to parse waincludes
 *
 * @param {Object} data the data from attributeSelect ajax call
 */
function updateKlarnaMessaging (data) {
	var $cart = $('.cart');
	if (data && data.klarnaInstallmentOptions && [undefined, null, ''].indexOf(data.klarnaInstallmentOptions.klarnaMessageHtml) === -1) {
		var $klarnaTemplate = $(data.klarnaInstallmentOptions.klarnaMessageHtml);
		var $klarnaMessage = $klarnaTemplate.filter('.js-klarna-msg');
		var $klarnaModalContent = $klarnaTemplate.filter('.js-klarna-modal-content');

		if ($klarnaMessage.length === 0) {
			$klarnaMessage = $klarnaTemplate.find('.js-klarna-msg');
		}

		if ($klarnaModalContent.length === 0) {
			$klarnaModalContent = $klarnaTemplate.find('.js-klarna-modal-content');
		}

		if ($klarnaMessage.length !== 0) {
			$cart.find('.js-klarna-msg').replaceWith($klarnaMessage);
		}

		if ($klarnaModalContent.length !== 0) {
			$cart.find('.js-klarna-modal-content').replaceWith($klarnaModalContent);
		}
	}
}

function updateCustomMessaging (data) {
	var $cart = $('.cart');
	for (let index = 0; index < data.length; index++) {
		var item = data[index];

		$cart.find('.uuid-' + item.UUID).find('.js-product-custom-messaging').empty().html(item.customMessageHtml || '');
	}
}

function rewardToggleChanged(toggle) {
	$.spinner().start();
	$('body').trigger('cart:beforeUpdate');
	var url = '';

	if (toggle.checked) {
		url = toggle.dataset.addreward
	} else {
		url = toggle.dataset.removereward
	}

	$.ajax({
		url: url,
		type: 'get',
		context: this,
		dataType: 'json',
		success: function (data) {
			if ('ResultInfo' in data) {
				console.log(data.ResultInfo.Message);
				if (data.ResultInfo.RedemptionAddedToCart || data.ResultInfo.RedemptionRemovedFromCart) {
					coreCart.updateCartTotals(data.Basket);
					coreCart.validateBasket(data.Basket);
					$('.coupons-and-promos').empty().append(data.Basket.totals.discountsHtml);
				}
			}

			$('body').trigger('cart:update', data);
			$.spinner().stop();
		},
		error: function (err) {
			console.log('In error handler of Reward Toggle Changed');
			if (err.responseJSON.redirectUrl) {
				window.location.href = err.responseJSON.redirectUrl;
			} else {
				coreCart.createErrorNotification(err.responseJSON.errorMessage);
				$.spinner().stop();
			}
		}
	});
}

coreCart.init = function () {
	coreCartInit.apply(this, arguments);

	$('body').on('promotion:success', function(event, data) {
		$('.coupon-section').empty().append(data.totals.couponHtml);
		$('.js-coupon-form')[$('.js-coupon-applied-section').length > 0 ? 'addClass' : 'removeClass']('js-coupon-applied');
	});

	$('body').on('cart:update cart:shippingMethodSelected', function(event, data) {
		updateKlarnaMessaging(data);
	});

	$('body').on('cart:update', function(event, data) {
		updateCustomMessaging(data.items || data.cartModel?.items || []);
	});

	$('.cart-rewards-toggle-input').on('change', function () {
		rewardToggleChanged(this);
	});
}

module.exports = coreCart;

/*{
	getPidValue: getPidValue,
	appendToUrl: appendToUrl,
	validateBasket: validateBasket,
	updateCartTotals: updateCartTotals,
	createErrorNotification: createErrorNotification,
	updateApproachingDiscounts: updateApproachingDiscounts,
	updateAvailability: updateAvailability,
	updateProductDetails: updateProductDetails,
	confirmDelete: confirmDelete,
	init: init
};
*/