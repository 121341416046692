'use strict';

var debounce = require('lodash/debounce');

var sitePreferencesUtils = require('core/preferences/sitePreferencesUtils');
var preferences = sitePreferencesUtils.getSitePreferences();
var SiteConstants = require('constants/SiteConstants');
// Original vars from search.js
var minChars = preferences.headerSearchMinTermLength;
var UP_KEY = SiteConstants.UP_KEY;
var DOWN_KEY = SiteConstants.DOWN_KEY;
var DIRECTION_DOWN = SiteConstants.DIRECTION_DOWN;
var DIRECTION_UP = SiteConstants.DIRECTION_UP;

var base = require('core/components/search');

// this function is intentionally left blank because it's easier this way;
// otherwise there's gonna lots of functions to copy over just because we don't need to overwrite
// top position for mobile search anymore
base.positionSuggestions = function () { };

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
base.processResponse = function (response) {
    var $suggestionsWrapper = module.exports.getSuggestionsWrapper(this).empty();

    $.spinner().stop();

    if (typeof (response) !== 'object') {
        $suggestionsWrapper.append(response).show();
        $(this).siblings('.reset-button').addClass('d-md-block');
        module.exports.positionSuggestions(this);

        if (module.exports.isMobileSearch(this)) {
            module.exports.toggleSuggestionsIcon('close');
            module.exports.applyModals(this);
        }

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }
    } else {
        $suggestionsWrapper.hide();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
base.getSuggestions = function (scope) {
    var endpoint = $('.suggestions-wrapper').data('url');

    if ($(scope).val().length >= minChars) {
        $.spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: module.exports.processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        module.exports.toggleSuggestionsIcon('search');
        $(scope).siblings('.reset-button').removeClass('d-md-block');
        module.exports.clearModals();
        module.exports.getSuggestionsWrapper(scope).empty();
    }
}

base.searchKeyListener = function () {
    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(module.exports.getSuggestions, 300);
        $(this).on('keyup focus', function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    module.exports.handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    module.exports.handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });
}

base.resetSearch = function () {
    $('.site-search .reset-button').on('click', function () {
        $(this).removeClass('d-md-block');
    });
}

module.exports = base;
