'use strict';

var baseMiniCart = require('core/components/miniCart');

module.exports = function () {
    baseMiniCart.apply(this, arguments);

    // Setup simple observer
    $('.minicart-quantity').each(function (i, el) {
        var observer = new MutationObserver(() => {
            var count = parseInt(el.textContent);

            el.classList[count !== 0 ? 'remove' : 'add']('no-items');
        });

        observer.observe(el, {childList: true});
    })
};
