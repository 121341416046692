'use strict';

var debounce = require('lodash/debounce');
var SiteConstants = require('constants/SiteConstants');
var hoverIntent = require('jquery-hoverintent');

var base = require('core/components/menu');

// for mobile, when backdrop is clicked, hide it if it was opened by search input
function mobileSearchFocusedListener() {
    $('.search-field').on('keyup focus', () => {
        $('body').addClass('js-mobile-search-focused');
    });

    $('html').on('click', '.js-mobile-search-focused .modal-background', () => module.exports.toggleDrawer('close'));
}

base.mobileSearchFocusedListener = mobileSearchFocusedListener;
base.dropdownMenu = function () {
    // Custom dropdown behaviors for top menu
    var $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');
    $dropdownMenu.on('click', event => {
        event.stopPropagation(); // Prevent Bootstrap dropdown click events
        if (window.isMobile()) {
            // Drawer behavior on mobile
            event.preventDefault();
            var $dropdownLink = $(event.target);
            var $dropdown = $dropdownLink.closest('li.custom-dropdown');

            if ($dropdown.length) {
                $('.multilevel-dropdown').each((i, el) => el.scrollTo({top: 0, left: 0, behavior: "instant"}));

                var $li = $('<li class="dropdown-item top-category" role="button"></li>');
                var $closeMenu = $('<li class="nav-menu"></li>');
                var link = $dropdownLink.clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                $li.append(link);
                $closeMenu.append($('.close-menu').first().clone());
                $dropdown.children('.dropdown-menu')
                    .prepend($li)
                    .prepend($closeMenu)
                    .attr('aria-hidden', 'false')
                    .find('a').removeAttr('tabindex', '-1');
                $dropdown.addClass('show');
                $dropdownLink.attr('aria-expanded', 'true');
                $dropdown.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
            }
        }
    });

    // Desktop - open menu using hoverIntent to prevent unintentional opening
    $dropdownMenu.hoverIntent({
        over: event => {
            if (!window.isMobile()) {
                var eventElement = event.target,
                    $eventElement = $(eventElement);
                // Close all dropdowns
                $('.navbar-nav > li').each((index, element) => {
                    if (!$.contains(element, eventElement)) {
                        var $navItem = $(element);
                        $navItem.find('.show').each(() => {
                            base.clearSelection(element);
                        });
                        if ($navItem.hasClass('show')) {
                            $navItem.removeClass('show');
                            $navItem.children('ul.dropdown-menu').removeClass('show');
                            $navItem.children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // Open current dropdown
                $eventElement.parent().addClass('show');
                $eventElement.siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                $eventElement.attr('aria-expanded', 'true');
            }
        },
        out: () => {
            // this 'out' handler is left empty, since it's triggered from parent, handled in mouseleave event below
        },
        interval: 75
    });

    $dropdownMenu.each(function () {
        var $menu = $(this);

        // Desktop - close menu on mouseleave contextual to
        var hideMenu = function (event) {
            if (!window.isMobile()) {
                var $dropdown = $(event.currentTarget);
                // Close current dropdown
                $dropdown.removeClass('show');
                $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
                $dropdown.children('.nav-link').attr('aria-expanded', 'false');
            }
        };
        var delayedHideMenu = debounce(hideMenu, SiteConstants.TransitionSpeed);

        // Cancel hide command if mouse has re-entered menu before menu is hidden within transition timeout
        $menu.parent()
            .on('hideimmediately', hideMenu)
            .on('mouseleave', delayedHideMenu)
            .on('mouseenter', () => delayedHideMenu.cancel());
    });
};

module.exports = base;
